import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { shuffle, find, reject, get, isFunction } from '../lib/nodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, ThemeContext } from 'grommet';

import ContentSlider from './ContentSlider';
import SliderGalleryItem from './SliderGalleryItem';
import { viewPlacementDetails } from '../state/gallery/gallerySlice';
import useMobile from './useMobile';

import Loading from './Loading';

const SliderContainer = styled(Box)`
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 5px;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 5px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    max-width: 16px;
    background-repeat: no-repeat;

    &:after {
      content: none;
    }
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }
  .swiper-pagination-bullet-active {
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    opacity: 1;
  }
  .swiper-button-prev {
    background-size: contain;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
  }
  .swiper-button-next {
    background-size: contain;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
  }

  .swiper-container .swiper-button-prev.pdp-gallery {
    display: none;
  }

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    .swiper-button-next,
    .swiper-button-prev {
      max-width: 10px;
    }
  }

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 5px;
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 5px;
    }
  }
`;

const SliderGallery = ({
  identifier,
  mainProductTitle,
  mainProductDescription,
  activePlacementId,
  onViewPlacement,
  placements = [],
  loading,
  ...rest
}) => {
  const nextEl = useRef();
  const prevEl = useRef();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [rendered, setRendered] = useState(false);

  const isMobile = useMobile();

  const swiperParams = {
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true,
    },
    loop: true,
    slidesPerView: isMobile ? 1 : 4,
    centeredSlides: isMobile,
    spaceBetween: 14,
    preloadImages: false,
    watchSlidesVisibility: true,
    navigation: {
      nextEl: nextEl.current,
      prevEl: prevEl.current,
    },
  };

  const arrangedPlacements = useMemo(
    () =>
      shuffle(placements ? reject((x) => !get('ugc.file', x), placements) : []),
    [placements]
  );

  const activeIndex = arrangedPlacements.indexOf(
    find({ id: activePlacementId }, arrangedPlacements)
  );

  const handleView = useCallback((placementId) => {
    if (onViewPlacement && isFunction(onViewPlacement)) {
      onViewPlacement({
        placementId,
        placements: arrangedPlacements,
        identifier,
      });
    } else {
      dispatch(
        viewPlacementDetails({ placementId, placements: arrangedPlacements })
      );
    }
  });

  useEffect(() => {
    if (nextEl.current && prevEl.current) {
      setRendered(true);
    }
  }, [nextEl, prevEl]);

  return (
    <SliderContainer {...rest}>
      <div style={{ position: 'relative' }}>
        <div ref={nextEl} className="swiper-button-next pdp-gallery" />
        <div ref={prevEl} className="swiper-button-prev pdp-gallery" />
        {loading || !rendered ? (
          <Loading />
        ) : (
          <ContentSlider
            activeSlide={activeIndex}
            params={swiperParams}
            navColor="000000"
            slideBackground="transparent"
            swiperStyle={{ margin: '40px 40px 60px 40px', width: '100%' }}
          >
            {arrangedPlacements.map((x) => (
              <div key={x.id}>
                <SliderGalleryItem
                  {...x}
                  key={x.id}
                  onView={handleView}
                  mainProductTitle={mainProductTitle}
                  mainProductDescription={mainProductDescription}
                  sizes={`(max-width: ${theme.global.breakpoints.small.value}px) 80vw, 22vw`}
                >
                  {x.index}
                </SliderGalleryItem>
              </div>
            ))}
          </ContentSlider>
        )}
      </div>
    </SliderContainer>
  );
};

SliderGallery.propTypes = {
  identifier: PropTypes.string.isRequired,
  mainProductTitle: PropTypes.string,
  mainProductDescription: PropTypes.string,
  activePlacementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placements: PropTypes.array,
  loading: PropTypes.bool,
  onViewPlacement: PropTypes.func,
};

export default memo(SliderGallery);
