import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get, lowerCase } from '../lib/nodash';
import ImgixImage from './ImgixImage';

const SliderGalleryItem = ({
  id,
  ugc,
  onView,
  mainProductTitle,
  mainProductDescription,
  sizes,
}) => {
  const pinterestDescription = `${mainProductTitle}, ${lowerCase(
    mainProductDescription
  )} paint color by Backdrop. `;

  const { file, attribution, alt_text } = ugc;
  const imgOpts = {
    fit: 'crop',
    crop: 'entropy',
    auto: ['compress', 'format'],
    ar: '1:1',
    q: '80',
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <ImgixImage
        src={get('url', file)}
        srcSetOptions={imgOpts}
        data-pin-description={pinterestDescription}
        alt={alt_text || attribution}
        onClick={() => onView(id)}
        className="swiper-lazy lazyload"
        lazy
        fill
        fit="cover"
        sizes={sizes}
      />
      <div className="swiper-lazy-preloader"></div>
    </div>
  );
};

SliderGalleryItem.propTypes = {
  id: PropTypes.number,
  onView: PropTypes.func,
  ugc: PropTypes.object,
  mainProductTitle: PropTypes.string,
  mainProductDescription: PropTypes.string,
  sizes: PropTypes.string,
};

export default memo(SliderGalleryItem);
